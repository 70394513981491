// React Basic and Bootstrap
import React from "react";
import { Link, graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import { Helmet } from "react-helmet";

function Blog(props) {
  const blogPosts = props.data.allMarkdownRemark.nodes;

  return (
    <React.Fragment>
      <Helmet title="Shopify & Gatsby JS 🛍️ A headless commerce blog">
        <meta
          name="description"
          content="A blog about headless commerce with Shopify and Gatsby JS."
        />
      </Helmet>
      <div className="py-6 sm:py-16 bg-gray-50 overflow-hidden">
        <nav
          className="flex max-w-7xl mx-auto px-4  sm:px-6 lg:px-8"
          aria-label="Breadcrumb"
        >
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  {/* <!-- Heroicon name: solid/home --> */}
                  <svg
                    className="flex-shrink-0 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <Link
                  to="/blog/"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  Blog
                </Link>
              </div>
            </li>
          </ol>
        </nav>

        <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto py-12 px-4  sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                Shopify & Gatsby JS 🛍️ A headless commerce blog
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-50 pb-20 px-4 sm:px-6  lg:pb-28 lg:px-8">
        <div className="relative max-w-7xl mx-auto">
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {blogPosts.map((blog, key) => (
              <div
                key={key}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                <div className="flex-shrink-0">
                  {blog.frontmatter.image ? (
                    <GatsbyImage
                      top
                      fluid={blog.frontmatter.image.childImageSharp.fluid}
                      className="rounded m-6"
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-blue-600">
                      <span className="hover:underline">Article</span>
                    </p>

                    <Link
                      to={`/blog/${blog.frontmatter.slug}/`}
                      className="block mt-2"
                    >
                      <p className="text-xl font-semibold text-gray-900">
                        {blog.frontmatter.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {blog.frontmatter.shortDescription}
                      </p>
                    </Link>
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="flex-shrink-0">
                      <span className="sr-only">{blog.frontmatter.author}</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src="https://gravatar.com/avatar/3e470894dd679ab67ff98df36731fb36"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {blog.frontmatter.author}
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime={blog.frontmatter.date}>
                          {blog.frontmatter.date}
                        </time>
                        <span aria-hidden="true">&middot;</span>
                        <span>3 min read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          author
          date(formatString: "D MMMM, YYYY")
          slug
          title
          shortDescription
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default Blog;
